import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { take, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService implements CanActivate {
  constructor(private auth: AngularFireAuth, private router: Router) {}
  canActivate() {
    return this.auth.user.pipe(
      take(1),
      map((authState) => !!authState),
      tap((authenticated) => {
        if (!authenticated) this.router.navigate(["/login"]);
      })
    );
  }
}
