import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(private api: ApiService, private fb: FormBuilder) {}
  public loginForm: FormGroup;

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        ),
      ]),
      password: new FormControl(null, Validators.required),
    });
    this.loginForm.valueChanges.subscribe((d) => console.log("change", d));
  }

  login() {
    if (this.loginForm.invalid) return;
    this.api.login(this.loginForm.value);
  }
}
