import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { OrderTrack } from '../../types/schemas';

@Component({
  selector: 'app-order-delivered-card',
  templateUrl: './order-delivered-card.component.html',
  styleUrls: ['./order-delivered-card.component.scss']
})
export class OrderDeliveredCardComponent {
  @Input() order: OrderTrack;
  @Output() undeliver = new EventEmitter();
  undeliverEmit() {
    this.undeliver.emit();
  }
}
