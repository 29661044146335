import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { OrderTrack } from '../../types/schemas';

@Component({
  selector: "app-mng-delivered",
  templateUrl: "./mng-delivered.component.html",
  styleUrls: ["./mng-delivered.component.scss"],
})
export class MngDeliveredComponent implements OnInit {
  constructor(private api: ApiService) {}
  public orders$ = this.api.getDelivered();
  ngOnInit(): void {}
  undeliver(order: OrderTrack) {
    this.api.undeliver(order);
  }
}
