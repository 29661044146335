import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { CoreModule } from './core/core.module';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

const config = {
  apiKey: "AIzaSyDzLxmyfiEuNNDUtgCgXiQz0GC_kV1KWxs",
  authDomain: "portapizza-track.firebaseapp.com",
  databaseURL: "https://portapizza-track.firebaseio.com",
  projectId: "portapizza-track",
  storageBucket: "portapizza-track.appspot.com",
  messagingSenderId: "957032202565",
  appId: "1:957032202565:web:56635e17cd87c65880fe2c",
  measurementId: "G-PFLE0PHPR6",
};
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
