import { Component, OnInit, Input } from "@angular/core";
import { OrderTrack, OrderStatus } from "../../types/schemas";

@Component({
  selector: "app-order-status-card",
  templateUrl: "./order-status-card.component.html",
  styleUrls: ["./order-status-card.component.scss"],
})
export class OrderStatusCardComponent {
  @Input() order: OrderTrack;
  statusColor = {
    [OrderStatus.BAKING]: "#BE0000",
    [OrderStatus.DELIVERING]: "#FFBD00",
    [OrderStatus.MAKING]: "#00A2FF",
    [OrderStatus.READY]: "#B0BF00",
  };
  numReceipt() {
    const numReceiptStr = this.order.numReceipt.toString();
    return numReceiptStr.slice(numReceiptStr.length - 2);
  }
}
