import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-client-track',
  templateUrl: './client-track.component.html',
  styleUrls: ['./client-track.component.scss']
})
export class ClientTrackComponent implements OnInit {
  constructor(private api: ApiService) { }
  public orders$ = this.api.getOrdersInProcess();
  ngOnInit(): void {
  }

}
