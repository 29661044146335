import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrderStatusCardComponent } from "./components/order-status-card/order-status-card.component";
import { OrderDeliveredCardComponent } from "./components/order-delivered-card/order-delivered-card.component";
import { OrderToDeliverCardComponent } from "./components/order-to-deliver-card/order-to-deliver-card.component";
import { ClientTrackComponent } from "./containers/client-track/client-track.component";
import { MngDeliverComponent } from "./containers/mng-deliver/mng-deliver.component";
import { MngDeliveredComponent } from "./containers/mng-delivered/mng-delivered.component";
import { LoginComponent } from "./components/login/login.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    OrderStatusCardComponent,
    OrderDeliveredCardComponent,
    OrderToDeliverCardComponent,
    ClientTrackComponent,
    MngDeliverComponent,
    MngDeliveredComponent,
    LoginComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
})
export class CoreModule {}
