import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { OrderTrack, OrderStatus } from "../../types/schemas";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    private store: AngularFirestore,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {}

  // Queries
  getOrdersInProcess(): Observable<any> {
    return this.store.collection("ordersInProcess").valueChanges();
  }
  getDelivered() {
    return this.store.collection("orders").valueChanges();
  }
  getDispatched() {
    return this.getOrdersInProcess();
  }

  // Commands
  deliver(order: OrderTrack) {
    this.store
      .collection("ordersInProcess")
      .doc(order.numReceipt.toString())
      .delete();
    this.store
      .collection("orders")
      .doc(order.numReceipt.toString())
      .set({ ...order, status: OrderStatus.DELIVERED });
  }
  undeliver(order: OrderTrack) {
    this.store.collection("orders").doc(order.numReceipt.toString()).delete();
    this.store
      .collection("ordersInProcess")
      .doc(order.numReceipt.toString())
      .set({ ...order, status: OrderStatus.READY });
  }

  // login
  login({ email, password }) {
    this.afAuth.signInWithEmailAndPassword(email, password).then((data) => {
      this.router.navigate(["to-delivery"]);
    });
  }
}
