<div class="card">
  <div class="receipt">
    <span>PEDIDO</span>
    <br />
    <span># {{ numReceipt() }}</span>
  </div>
  <div class="status" [style.background]="statusColor[order.status]">
    {{ order.status }}
  </div>
</div>
