<div class="card">
  <div class="receipt">
    <span>ENTREGADO</span>
    <br />
    <span>#{{ order.numReceipt }}</span>
    <br>
    <span>{{ order.clientData }}</span>
  </div>
  <div class="deliver" (click)="undeliverEmit()">
    NO ENTREGADO
  </div>
</div>
