import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ClientTrackComponent } from "./core/containers/client-track/client-track.component";
import { MngDeliverComponent } from "./core/containers/mng-deliver/mng-deliver.component";
import { AuthService } from "./core/services/auth/auth.service";
import { LoginComponent } from './core/components/login/login.component';
import { MngDeliveredComponent } from './core/containers/mng-delivered/mng-delivered.component';

const routes: Routes = [
  {
    path: "client",
    component: ClientTrackComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "to-delivery",
    canActivate: [AuthService],
    component: MngDeliverComponent,
  },
  {
    path: "delivered",
    canActivate: [AuthService],
    component: MngDeliveredComponent,
  },
  {
    path: "**",
    redirectTo: 'client'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
