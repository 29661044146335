import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { OrderTrack } from "../../types/schemas";

@Component({
  selector: "app-order-to-deliver-card",
  templateUrl: "./order-to-deliver-card.component.html",
  styleUrls: ["./order-to-deliver-card.component.scss"],
})
export class OrderToDeliverCardComponent {
  @Input() order: OrderTrack;
  @Output() deliver = new EventEmitter();
  deliverEmit() {
    this.deliver.emit();
  }
}
