<div class="card">
  <div class="receipt">
    <span>PEDIDO</span>
    <br />
    <span>#{{ order.numReceipt }}</span>
    <br>
    <span>{{ order.clientData }}</span>
  </div>
  <div class="deliver" (click)="deliverEmit()">
    ENTREGAR
  </div>
</div>
