import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { OrderTrack } from '../../types/schemas';

@Component({
  selector: "app-mng-deliver",
  templateUrl: "./mng-deliver.component.html",
  styleUrls: ["./mng-deliver.component.scss"],
})
export class MngDeliverComponent implements OnInit {
  constructor(private api: ApiService) {}
  public orders$ = this.api.getDispatched();
  ngOnInit(): void {}
  deliver(order: OrderTrack) {
    this.api.deliver(order);
  }
}
