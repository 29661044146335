export type OrderTrack = {
  numReceipt: number;
  status: OrderStatus;
  clientData: string;
  createdAt: Date;
  readyAt: Date;
};

export enum OrderStatus {
  MAKING = "preparando",
  BAKING = "en horno",
  READY = "listo",
  DELIVERING = "en reparto",
  DELIVERED = "entregado",
}
